<template>
    <div>
      <CustomDetail :issueId="issueId" :isFromIssue="true"></CustomDetail>
    </div>
  </template>

<script>
import CustomDetail from './CustomDetail'
export default {
  name: 'IssueDetail',
  components: { CustomDetail },
  computed: {
    issueId () {
      return this.$route.params.issueId
    }
  },
  data () {
    return {
    }
  },
  created () {
    // ...
  },
  methods: {
  }
}
</script>

  <style scoped lang="scss">
  </style>
